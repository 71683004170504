<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">MON<br>PORTFOLIO</h1>      
      <p class="maintext">Chaque navire est mené à bon port grâce au savoir-faire et à l'expérience d'un capitaine.Alors venez en apprendre un peu plus sur moi et découvrez mes réalisations vidéo sur ma chaîne Youtube, ainsi que les sites internet que j'ai créés.</p>
    </section>
    <section id="anchor" class="rea">
      <a href="https://www.behance.net/lmcreanum/" class="about__button about__button__portfolio">DECOUVREZ MON PORTFOLIO COMPLET SUR BEHANCE</a>
      <h3 class="rea__title revealleft">VIDEOS</h3>
      <div class="rea__upper">
          <iframe width="840" height="472" src="https://www.youtube.com/embed/videoseries?list=PL75aT1Iy1-YBE8dBWAtzOeOQMmZDr6_VN" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen class="revealright video"></iframe>
      </div>
    </section>
    <Reaslideshow/>
    <section class="rea__bottom">
      <div class="rea__invert">
      <h3 class="rea__title__right revealright">SITES WEB</h3>
      <div class="rea__down">        
        <div class="rea__slide">
          <button class="controls" id="previous"><i class="fas fa-chevron-left"></i></button>
          <div id="container">
            <ul id="all_slides">
            <a href="https://misax.fr" class="slide"></a>
            <a href="https://chambre-hote-capbreton.fr/" class="slide"></a>
            <a href="https://shop.superbus.fr" class="slide activ" alt="Boutique officielle de Superbus"></a>
            <a href="https://www.superbus.fr"  class="slide" alt="Site officiel de Superbus"></a>
            <a href="https://angelique-montagner.notaires.fr" class="slide" alt="Site de Maitre Montagner notaire Seignosse"></a>
            <a href="./" class="slide"></a>
            <a href="https://welovesuperbus.com" class="slide"></a>
            <a href="https://assoyogafacondetre.com/fr/" class="slide"></a>
            </ul>
          </div>
          <button class="controls" id="next"><i class="fas fa-chevron-right"></i></button>
          </div>
      </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';
import Reaslideshow from '../components/reaslideshow.vue';

export default {
  name: 'Home',
  components: {
    Reaslideshow
  },
  mounted() {
        /**************** Apparition au scroll *************/
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);

        /********************* slideshow ******************/
        "use strict";


          let slides = document.querySelectorAll('#all_slides .slide');
          let currentSlide = 0;

          const nextSlide = () => {
              goToSlide(currentSlide + 1);
          }

          const previousSlide = () => {
              goToSlide(currentSlide - 1);
          }

          let slideInterval = setInterval(nextSlide, 3000); // Ici Vitesse

          const goToSlide = (s) => {

              slides[currentSlide].className = 'slide';
            
              currentSlide = (s + slides.length) % slides.length;
              slides[currentSlide].className = 'slide activ';
          }

          const pauseSlideshow = () => {
              clearInterval(slideInterval);
          }
          const playSlideshow = () => {
              slideInterval = setInterval(nextSlide, 3000); // Ici vitesse
          }

          let next_btn = document.querySelector('#next');
          let previous_btn = document.querySelector('#previous');

          next_btn.onclick = () => {
            pauseSlideshow();
              nextSlide();
            playSlideshow();
          };
          previous_btn.onclick = () => {
              pauseSlideshow();
              previousSlide();
              playSlideshow();
          };

  }
}
</script>

<style lang="scss">
.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

.video {
  @media (max-width: 768px) {
		width: 90%;
	}
  
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

.rea {
  width: 100%;
  margin:auto;
  background-image: url('../assets/wavej4.svg');
  background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 980px){
            background-size: cover;
            background-position-y: 300px;
    }
  &__bottom {
  transform: scaleX(-1);
  width: 100%;
  margin:auto;
  background-image: url('../assets/svg-path4.svg');
  background-size: cover; 
  background-position-y: center;
    background-repeat: no-repeat;
    overflow: hidden;
    @media screen and (max-width: 980px){
            background-size: cover;
            background-position-y: 300px;
    }
  }
  &__invert {
    transform: scaleX(-1);
  }
  &__upper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
  }
  &__down {
    display: block;
  }
  &__text {
    width: 50%;
    max-width: 600px;
    font-size: smaller;
  }
  &__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 90%;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01fab;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
    &__right {
      font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 90%;
    text-align: right;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01fab;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
    }
  }
}

.aboutbutton {
    border: 2px solid #386ed8;
    width: fit-content;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            }
}

.about__button {
     border: 2px solid #386ed8;
    width: fit-content;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    &__portfolio {
      @media screen and (max-width: 980px){
            width: 90%!important;
            text-align: center;
            margin-bottom: 20px;
            }
    }
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
    border: 2px solid #386ed8;
    width: fit-content;
    padding: 15px 15px 12px;
           color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            }
}

.aboutbuttonrea {
    width: fit-content;
    padding: 17px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
    color: white;
    background: linear-gradient(335deg, rgba(28,9,224,1) -50%, rgba(56,224,31,1) 100%);
     &:hover {
            background: linear-gradient(335deg, rgba(28,9,224,1) 0%, rgba(56,224,31,1) 100%);
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background: linear-gradient(335deg, rgba(28,9,224,1) 0%, rgba(56,224,31,1) 100%);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            }
}

.aboutbuttonline {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  /************** Slideshow */

  #container {
    width: 60%;
    aspect-ratio: 4 / 2;
    margin: auto;
    overflow: hidden;
    @media screen and (max-width: 769px){
            width: 90%;
            aspect-ratio: 2 / 3;
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 90%;
            aspect-ratio: 2 / 3;
  }
}

.rea__slide {
  display: flex;
  align-items: center;
}

#all_slides{
  position: relative;
  padding: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
  list-style-type: none;
}

.slide{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}

.activ{
  opacity: 1;
  z-index: 2;
  background-color: transparent!important;
}

.slide{
  font-size: 40px;
  padding: 40px;
  box-sizing: border-box;
  color: #fff;
  background-size: cover;
}

.slide:nth-of-type(1){
  background-image: url('../assets/mu-misax.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-misax-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-misax-mob.webp');
  }
}

.slide:nth-of-type(2){
  background-image: url('../assets/mu-maisonjaune.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-maisonjaune-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-maisonjaune-mob.webp');
  }
}

.slide:nth-of-type(3){
  background-image: url('../assets/mu-shop-superbus.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-shop-superbus-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-shop-superbus-mob.webp');
  }
}

.slide:nth-of-type(4){
  background-image: url('../assets/mu-superbus.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-superbus-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-superbus-mob.webp');
  }
}

.slide:nth-of-type(5){
  background-image: url('../assets/mu-notaire.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-notaire-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-notaire-mob.webp');
  }
}

.slide:nth-of-type(6){
  background-image: url('../assets/mu-lmcreanum.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-lmcreanum-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-lmcreanum-mob.webp');
  }
}

.slide:nth-of-type(7){
  background-image: url('../assets/mu-wls.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-wls-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-wls-mob.webp');
  }
}

.slide:nth-of-type(8){
  background-image: url('../assets/mu-assoyogafacondetre.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 769px){
            background-image: url('../assets/mu-assoyogafacondetre-mob.webp');
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    background-image: url('../assets/mu-assoyogafacondetre-mob.webp');
  }
}

.controls{
  display: none;
}

.controls{
  display: inline-block;
  position: relative;
  top: 1rem;
  right: .5rem;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  margin-left: .5rem;
  background-color: transparent;
}


.container{
  position: relative;
}
</style>
