<template>
  <div class="blocksliderea parallax-demo">
    <div data-v-2f282228="" class="curve-shape curve-bottom" id="bottom-curverea"><svg data-v-2f282228="" x="0px" y="0px" viewBox="0 0 3841 120" xml:space="preserve" style="overflow: scroll;"><path data-v-2f282228="" d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z" style="fill: rgb(255, 255, 255); stroke-miterlimit: 10;"></path></svg></div>
    <div class="slidecontainer">
        <h2 class="slidetitle">A PROPOS DE MOI</h2>
        <div class="about">
            <img src="../assets/pdp.webp" alt="Photo de profil de Laurie" class="about__pdp revealleft">
             <p class="about__text revealright">Après un parcours atypique croisant l'aéronautique, le cinéma (Master Création Audiovisuelle option Infographie à l’ENSAV) et le développement web (Bac+2 de Développer Web sur OpenClassrooms), j'ai à cœur aujourd'hui de mettre mes compétences à votre service ! La variété des projets qui m’ont été confiés ont pu renforcer mes compétences dans les domaines du web, de la vidéo et du marketing de contenu, tout en me permettant de me créer un bon bagage d'expériences. Principalement spécialisée en Front-End, mais aussi Wordpress et Prestashop, je suis également passionnée par l'animation 2D et le motion design, que ce soit en vidéo ou en code. Et comme j'aime aussi prendre l'air loin de mon ordinateur, je suis toujours partante pour vous suivre sur un tournage ! Originaire de la Lomagne Tarn-et-Garonnaise, je tiens à participer au rayonnement de mon petit coin de campagne, via le développement en ligne de ses entreprises. Je suis donc disponible pour tous vos projets de sites internet, production vidéo et création de contenu dans la Lomagne, le Tarn-et-Garonne, Gers, Landes et plus généralement dans Grand Sud-Ouest. Mais, grâce au télétravail, mes services ne connaissent pas de frontières et je travaille régulièrement avec des clients de tout le pays et même d’ailleurs ! Alors, n’hésitez pas à me contacter ! 
            </p>
    </div>
    </div>
    <div data-v-2f282229="" class="curve-shape" id="top-curverea">
      <svg data-v-2f282229="" x="0px" y="0px" viewBox="0 0 3841 120" xml:space="preserve" style="overflow: scroll;">
        <path data-v-2f282229="" d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z" style="fill: rgb(255, 255, 255); stroke-miterlimit: 10;"></path>
        </svg>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Header',
  props: {
    msg: String
  },
    mounted() {
        /********* Parralaxe */
      $.fn.parallax = function(opt) {
    return this.each(function() {
        var optDefault = {
                speed: 0.3
            },
            self = $(this),
            optDataSpeed = {
                speed: self.data('parallax-speed')
            },
            bpFirst = self.css('background-position').split(' ')[0],
            bpLast = self.css('background-position').split(' ')[1],
            opts = $.extend(optDefault, opt, optDataSpeed);
        function parallax() {
            var st = $(window).scrollTop(),
                wh = $(window).outerHeight(),
                o = self.offset(),
                h = self.outerHeight(),
                bp = bpFirst + ' ' + 'calc(' + bpLast + ' + ' + (o.top-st)*opts.speed + 'px)';
            if ( (st + wh >= o.top) && (st <= o.top + h) ) {
                self.css('background-position', bp);
            }
        }
        $(window).on('scroll', parallax);
    });
    }
    $('.parallax-demo').parallax({
        speed: 0.4
    });
    }
}
</script>

<style lang="scss">

.about {
  display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 979px) {
		flex-direction: column;
	}
  &__pdp {
    width: 50%;
    max-width: 350px;
    height: auto;
  }
  &__text {
    width: 50%;
    max-width: 600px;
    font-size: medium;
    color: white;
    @media (max-width: 979px) {
      width: 90%;
      margin-top: 30px;
      font-size: small;
  }
}
}

@keyframes move-left-data-v-2f282228 {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}

@keyframes move-left-data-v-2f282229 {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}

.blocksliderea {
  overflow: hidden;
  position: relative;
  background-image: url("../assets/seignosse6.webp");
  background-size: cover;
  background-position: center;
  height: 700px;
  margin-bottom: 40px;
  @media (max-width: 1440px) {
		height: 700px;
	}
  @media (max-width: 979px) {
		height: 850px;
	}
  @media (max-width: 768px) {
		height: 850px;
	}
  @media (max-width: 425px) {
		height: 950px;
	}
  @media (max-width: 300px) {
		height: 1000px;
	}
  @media (max-width: 215px) {
		height: 1200px;
	}
  @media (min-width: 1650px) {
		height: 720px;
	}
}

.curve-shape svg[data-v-2f282228] {
    width: 200%;
    position: relative;
    animation: move-left-data-v-2f282228 6s linear infinite;
}

.curve-shape svg[data-v-2f282229] {
    width: 200%;
    position: relative;
    animation: move-left-data-v-2f282229 5s linear infinite;
}

.curve-shape.curve-bottom {
    overflow: hidden;
    transform: rotateX(-180deg);
    margin-bottom: -160px!important;
}

.curve-shape[data-v-2f282228] {
    overflow-x: hidden;
}

.curve-shape[data-v-2f282229] {
    margin-bottom: -12px;
    overflow-x: hidden;
}

#bottom-curverea {
    position: relative;
    top: -22px;
    margin-bottom: 0 !important;
    @media (max-width: 2280px) {
		top: -18px
	}
     @media (max-width: 1440px) {
		top: -14px;
	}
    @media (max-width: 768px) {
		top: -10px;
	}
    @media (max-width: 425px) {
		top: -8px;
	}
     @media (max-width: 300px) {
		top: -7px;
	}
   
}

#top-curverea {
  position: absolute;
    bottom: -15px;
    width: 100%;
    @media (max-width: 2280px) {
		bottom: -7px;
	}
    @media (max-width: 1440px) {
		bottom: -3px;
	}
    @media (max-width: 768px) {
		bottom: 2px;
	}
    @media (max-width: 425px) {
		bottom: 4px;
	}

}

.slidetitle {
    font-family: 'Yanone Kaffeesatz';
    text-align: center;
    font-size: xxx-large;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}


.homeservices__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01f;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
}


</style>